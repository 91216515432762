@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$font: "Inter", sans-serif;
$navBgColor: rgba(126, 126, 126, 0.3);
$navShadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
$appBgColor: #f2f3f5;
$fontColor: #38393e;
$lightFont: #505d6f;
$tableHeader: #878787;
$borderColor: #545f7d26;
$boxShadowColor: #0000000a;
$gray: #757585;
$darkRed: #9d0105;
$gold: #c39e64;
$white: #ffffff;
$error: #e4033b;
$tiny: 0.625rem;
$xs: 0.75rem;
$md: 0.875rem;
$base: 1rem;
$lg: 1.25rem;
$xlg: 1.5rem;
$xxlg: 2.5rem;
$xxxlg: 3.5rem;
