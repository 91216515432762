@import "../../scss/_media-query-mixins.scss";
@import "../../scss/_sass-variables.scss";

.sidebar {
  display: none;
  position: fixed;
  top: 4.25rem;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  box-shadow: 0px 5px 20px $boxShadowColor;
  background-color: $navBgColor;
  color: $fontColor;
  overflow-y: auto;

  &.show_sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    width: 70%;
    background: #ccc;
    height: calc(100vh - 4.5rem);

    // opacity: 100%;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include md {
    font-size: $md;
    display: block;
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 14.69rem;
    height: calc(100vh - 4.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include lg {
    font-size: $base;
    width: 17.69rem;
    padding: 2.4rem 0;
  }

  &_logout {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: $tiny;
    padding: 0.5rem 1.125rem;
    margin: 0;
    margin-bottom: $tiny;
    font-size: $base;
    margin-top: 2rem;

    @include md {
      margin-top: 0;
    }

    &_icon {
      font-size: $lg;
      margin-right: $base;
    }

    &:hover {
      position: relative;
      background-color: $gold;
      // border-radius: 0 20px 0 20px;
    }

    @include lg {
      padding: 0.5rem 1.875rem;
      font-size: $base;
    }

    &_icon {
      width: $base;
      height: $base;
      object-fit: contain;
      // transform: rotate(90deg);
      // background-color: $yellow;
      border-radius: 80px;
      width: 40px;
      height: 27px;
      transition: all 0.2s ease-in-out;
      transform: rotate(340deg);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_text {
      margin: 0;
    }
  }
}
