@import "../../../scss/_media-query-mixins.scss";
@import "../../../scss/_sass-variables.scss";

.bar {
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 20px;
  margin-top: -6px;

  @include md {
    display: none;
  }

  span {
    position: absolute;
    width: 27px;
    height: 2px;
    background-color: black;
    border-radius: 100px;
    display: inline-block;
    transition: 0.3s ease;
    left: 0;

    &.top {
      top: 0;

      &.transform {
        transform: rotate(42deg);
        transform-origin: top left;
        width: 29px;
        left: 2px;
        box-shadow: 0 0 5px #495057;
      }
    }

    &.middle {
      top: 8px;

      &.transform {
        transform: translateX(-20px);
        opacity: 0;
      }
    }

    &.bottom {
      bottom: 0;

      &.transform {
        transform: rotate(-42deg);
        transform-origin: top left;
        width: 29px;
        bottom: -1px;
        box-shadow: 0 0 5px #495057;
      }
    }
  }
}
