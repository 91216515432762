@import "../../scss/_media-query-mixins.scss";
@import "../../scss/_sass-variables.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.45rem;
  max-height: 4.45rem;
  background-color: $navBgColor;
  padding: 1.2rem;
  box-shadow: 3px 0px 20px $boxShadowColor;

  @include sm {
    height: 5.25rem;
    padding: 1.875rem;
  }

  @include xl {
    height: 6.25rem;
  }

  &_column_one {
    flex: 1;
    display: flex;
    align-items: center;

    @include md {
      justify-content: space-between;
    }

    &_brand {
      display: flex;
      gap: $base;
      align-items: center;

      &_logo {
        width: 8rem;
        height: 6rem;
        object-fit: contain;

        @include md {
          width: 8rem;
          height: 8rem;
        }
      }

      &_name {
        display: none;

        @include sm {
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          font-weight: 600;
          font-size: $tiny;
          gap: 0.2rem;
        }

        &_top {
          color: $fontColor;
        }
        &_bottom {
          color: $darkRed;
        }
      }
    }

    // &_name{

    // }
  }

  &_column_two {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $base;
    font-size: $md;
    width: fit-content;

    @include sm {
      flex: 0.5;
      font-size: $base;
    }

    @include lg {
      flex: 1;
      gap: 2.1rem;
    }

    &_user {
      color: black;
      cursor: pointer;
    }
    &_image {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $gold;
      color: $gold;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.7rem;
    }
  }
}
