@import "../../../scss/_media-query-mixins.scss";
@import "../../../scss/_sass-variables.scss";

.nav_link_category {
  // padding: 0.5rem 1.125rem;
  // margin-bottom: $lg;

  @include mobile {
    // padding: 0.5rem 1.125rem;
    // margin-bottom: $lg;
    margin-left: 1.5rem;
  }

  &_sub {
    margin-left: 4rem;
    margin-bottom: 10px;
  }

  &_head {
    display: flex;
    align-items: center;
    margin-bottom: $lg;
    cursor: pointer;

    @include md {
      margin-left: 2.2rem;
    }

    &.active {
      position: relative;
      background-color: $gold;
      // color: #05ff69;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        background-color: $white;
      }
    }

    &_icon {
      font-size: $lg;
      margin-right: 2.2rem;

      // margin-right: $base;
    }

    &_heading {
      margin: 0;
      // font-size: $xs;
      // font-weight: 500;
      // margin-bottom: 1.125rem;
      // padding: 0 1.125rem;

      @include sm {
        // font-size: $xs;
        // margin-bottom: 1.25rem;
      }

      @include lg {
        // padding: 0 1.875rem;
      }
    }
  }
}
