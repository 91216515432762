@import "../../scss/_media-query-mixins.scss";

.app_layout {
  display: flex;
  flex-direction: column;
  flex: 1;

  &_container {
    display: flex;
    min-height: 100%;

    &_contents {
      flex: 1;
      padding: 1.25rem;
      max-height: 90vh;
      max-width: 100%;
      overflow-y: scroll;

      @include sm {
        padding: 1.5rem;
      }

      @include lg {
        padding: 2.5rem;
      }

      @include xl {
        padding: 3.75rem;
      }
    }
  }
}
