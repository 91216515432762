@import "../../../scss/_media-query-mixins.scss";
@import "../../../scss/_sass-variables.scss";

.side_nav_link {
  display: flex;
  align-items: center;
  gap: $tiny;
  padding: 0.5rem 1.125rem;
  margin-bottom: $lg;

  &_icon {
    font-size: $lg;
    margin-right: $base;
  }

  &.active {
    position: relative;
    background-color: $gold;
    // color: #05ff69;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: $white;
    }
  }

  @include lg {
    padding: 0.8rem 1.875rem;
  }

  &:hover {
    font-weight: 500;
    position: relative;
    background-color: $gold;
    // color: #05ff69;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      height: 100%;
      width: 3px;
      background-color: $white;
    }
  }

  // &-icon-cont {
  &_icon {
    width: $base;
    height: $base;
    object-fit: contain;
    border-radius: 80px;
    width: 40px;
    height: 27px;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // }

  &_text {
    margin: 0;
  }
}
